import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const ModalImage = ({url, open, onClose}) => {
  return <Dialog open={open} maxHeight='100%' maxWidth='100%' onClick={onClose} style={{cursor: 'zoom-out'}}>
    <DialogContent>
      <img alt='Imagen Via romana' src={url} style={{maxHeight: '85vh', maxWidth: '100%'}}/>
    </DialogContent>
  </Dialog>;
};

ModalImage.propTypes = {
  open: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalImage;