import React, {useContext, useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';

import Map from '@geomatico/geocomponents/Map';
import MarkerIcon from '@geomatico/geocomponents/MarkerIcon';

import useFetch from '../hooks/useFetch';
import {ConfigContext} from '../contexts/Config';
import bbox from '@turf/bbox';
import {
  FlyToInterpolator,
  GeolocateControl,
  LinearInterpolator,
  Marker,
  Popup,
  WebMercatorViewport
} from 'react-map-gl';
import Typography from '@mui/material/Typography';
import FlightIcon from '@mui/icons-material/Flight';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalImage from '../components/ModalImage';
import NumericScale from '../components/NumericScale';
import styled from '@mui/styles/styled';

const MARKER_SIZE = 20;

const PopupInfo = styled(Popup)({
  cursor: 'default'
});

const PopupImg = styled('img')({
  cursor: 'zoom-in',
  maxWidth: 360,
  marginTop: '4px'
});

const Geolocate = styled(GeolocateControl)({
  top: 25,
  left: 138,
  '& .mapboxgl-ctrl-group': {
    boxShadow: 'none',
    borderRadius: '0 4px 4px 0',
    borderStyle: 'solid solid solid none',
    borderWidth: '2px',
    borderColor: 'lightgray',
  },
  '& .mapboxgl-ctrl-icon': {
    height: 40,
    width: 40,
    '&:hover': {
      backgroundColor: 'white',
      borderRadius: '0 4px 4px 0',
    },
  }
});

const geolocateLabelStyle = {
  position: 'absolute',
  left: 15,
  top: 25,
  bgcolor: 'white',
  pt: 1.05,
  pb: 1,
  px: 1,
  borderRadius: '4px 0 0 4px',
  borderStyle: 'solid none solid solid',
  borderWidth: '2px',
  borderColor: 'lightgray',
  cursor: 'pointer'
};

const MainContent = ({mapStyle, via, onViaSelected, categories, isToponimiaVisible, isTerrainVisible, toogleGeolocateControl}) => {
  const {initialViewport, estados} = useContext(ConfigContext);

  const [viewport, setViewport] = useState(initialViewport);
  const [hovering, setHovering] = useState(null);
  const [popupInfo, setPopupInfo] = useState();
  const [modalImageOpen, setModalImageOpen] = useState(false);

  const {data: viasRomanas} = useFetch('viasromanas.geojson');
  const {data: viasNoDescritas} = useFetch('vias_no_descritas.geojson');
  const {data: fotos} = useFetch('fotos.geojson');

  const visibleStates = [...estados.filter(e => categories.includes(e.id)).flatMap(e => e.values), '-'];

  const filteredVias = viasRomanas ? {
    ...viasRomanas,
    features: viasRomanas.features.filter(feature => (!via || feature.properties.v === via) && visibleStates.includes(feature.properties.s))
  } : viasRomanas;

  const handleViewportChange = viewport => setViewport({
    ...viewport,
    ...(isTerrainVisible ? {} : {bearing: 0, pitch: 0}), // If there is no 3D, restrict movements
    transitionInterpolator: undefined
  });

  const handleHover = event => {
    const feature = event.features[0];
    feature?.layer.id === 'viasRomanas-selectable' ? setHovering(feature?.properties?.v) : setHovering();
  };

  const handleClick = event => {
    const feature = event.features[0];
    if (feature?.layer.id === 'viasRomanas-selectable') {
      onViaSelected && onViaSelected(feature?.properties?.v);
    } else {
      setPopupInfo();
      onViaSelected && onViaSelected();
    }
  };

  const handleImageClose = () => setModalImageOpen(false);

  const sources = useMemo(() => ({
    'mapbox-terrain': {
      type: 'raster-dem',
      url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
      tileSize: 512,
      maxzoom: 14
    },
    limite_cyl: {
      type: 'geojson',
      data: 'limite_cyl.geojson'
    },
    ciudadesRomanas: {
      type: 'geojson',
      data: 'ciudadesromanas.geojson'
    },
    viasRomanas: {
      type: 'geojson',
      data: viasRomanas
    },
    filteredVias: {
      type: 'geojson',
      data: filteredVias
    },
    viasNoDescritas: {
      type: 'geojson',
      data: viasNoDescritas
    }
  }), [viasRomanas, viasNoDescritas, filteredVias]);

  const terrain = useMemo(() => isTerrainVisible ? {
    source: 'mapbox-terrain',
    exaggeration: 2.0
  } : undefined, [isTerrainVisible]);

  const layers = useMemo(() => ([
    {
      id: 'viasNoDescritas',
      source: 'viasNoDescritas',
      type: 'line',
      maxzoom: 13.5,
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': via ? '#888' : '#663300',
        'line-width': via ? 2 : 5
      }
    },
    {
      id: 'limite-area',
      source: 'limite_cyl',
      type: 'fill',
      paint: {
        'fill-color': '#000',
        'fill-opacity': 0.2
      }
    },
    {
      id: 'limite',
      source: 'limite_cyl',
      type: 'line',
      paint: {
        'line-color': '#000',
        'line-width': 2
      }
    },
    {
      id: 'viasRomanas-hovering',
      source: 'viasRomanas',
      type: 'line',
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-blur': hovering ? ['case', ['==', ['get', 'v'], hovering], 4, 0] : 0,
        'line-color': hovering ? ['case', ['==', ['get', 'v'], hovering], '#FFFF00', '#131313'] : '#131313',
        'line-width': hovering ? ['case', ['==', ['get', 'v'], hovering], 13, 0] : 0
      }
    }, {
      id: 'viasRomanas-selectable',
      source: 'viasRomanas',
      type: 'line',
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#000',
        'line-opacity': via ? ['case', ['==', ['get', 'v'], via], 0, 1] : 0,
        'line-width': via ? ['case', ['==', ['get', 'v'], via], 0, 3] : 5
      }
    }, {
      id: 'viasRomanas-selected',
      source: 'filteredVias',
      type: 'line',
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['match', ['get', 's'],
          ...estados.flatMap(e => e.values.flatMap(v => [v, e.color])),
          '-', '#663300',
          '#000'
        ],
        'line-width': 5,
        'line-dasharray': ['case', ['==', ['get', 's'], 'H'], ['literal', [1, 2]], ['literal', []]],
        'line-opacity': via ? ['case', ['==', ['get', 'v'], via], 1, 0] : 1
      }
    },
    ...(isToponimiaVisible ? [
      {
        id: 'ciudadesRomanas_circle',
        source: 'ciudadesRomanas',
        type: 'circle',
        paint: {
          'circle-radius': 4,
          'circle-color': '#FFF',
          'circle-stroke-width': 1,
          'circle-stroke-color': '#000'
        }
      },
      {
        id: 'ciudadesRomanas_label',
        source: 'ciudadesRomanas',
        type: 'symbol',
        layout: {
          'text-font': ['Open Sans Bold'],
          'text-field': ['get', 'NAME'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'text-size': ['interpolate', ['linear'], ['zoom'], 5.9, 0, 6, 8, 9, 15, 15, 30],
        },
        paint: {
          'text-color': '#FFF',
          'text-halo-width': 1,
          'text-halo-color': '#000',
        }
      }] : []
    )
  ]), [hovering, via, isToponimiaVisible]);

  const flyToBbox = () => {
    if(viewport.width && viewport.height) {
      const selection = via ? {...viasRomanas, features: viasRomanas.features.filter(feature => feature.properties.v === via)} : viasRomanas;
      const [minLng, minLat, maxLng, maxLat] = bbox(selection);
      const {longitude, latitude, zoom} = new WebMercatorViewport(viewport)
        .fitBounds(
          [[minLng, minLat],[maxLng, maxLat]],
          {padding: 40}
        );
      setViewport({
        ...viewport,
        longitude,
        latitude,
        zoom,
        bearing: 0,
        transitionInterpolator: new FlyToInterpolator({speed: 1.2}),
        transitionDuration: 'auto'
      });
    }
    setHovering();
  };

  useEffect(flyToBbox, [via]);

  const tiltMap = () => setViewport({
    ...viewport,
    ...(isTerrainVisible ? {
      pitch: 70,
      transitionInterpolator: new LinearInterpolator(),
      transitionDuration: 1000
    } : {
      bearing: 0,
      pitch: 0,
      transitionInterpolator: undefined,
      transitionDuration: 0
    }),
  });

  useEffect(tiltMap, [isTerrainVisible]);

  const markers = useMemo(() => fotos && fotos.features.map((feature, index) =>
    <Marker
      key={index}
      longitude={feature.geometry.coordinates[0]}
      latitude={feature.geometry.coordinates[1]}
      offsetLeft={-MARKER_SIZE/2} offsetTop={-MARKER_SIZE}
    >
      <MarkerIcon
        icon={feature.properties.t === 'Aerea' ? FlightIcon : VisibilityIcon}
        color='#000'
        iconcolor='#fff'
        size={MARKER_SIZE}
        onClick={
          () => setPopupInfo({
            lon: feature.geometry.coordinates[0],
            lat: feature.geometry.coordinates[1],
            name: feature.properties.n,
            description: feature.properties.d
          })}
      />
    </Marker>), [fotos]);

  return viasRomanas && <Map
    mapboxAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
    mapStyle={mapStyle}
    viewport={viewport}
    onViewportChange={handleViewportChange}
    sprite='https://geoserveis.icgc.cat/contextmaps/sprites/sprite@1'
    sources={sources}
    terrain={terrain}
    layers={layers}
    interactiveLayerIds={['viasRomanas-selectable']}
    onHover={handleHover}
    onClick={handleClick}
    maxPitch={70}
  >
    <NumericScale zoom={viewport.zoom} lat={viewport.latitude} sx={{
      left: 8,
      bottom: 35,
      fontSize: 11,
      padding: '2px 6px',
      backgroundColor: '#ffffffaa'
    }}/>

    {viewport.zoom > 9 && markers}
    {popupInfo && <PopupInfo
      latitude={popupInfo.lat}
      longitude={popupInfo.lon}
      closeButton={true}
      closeOnClick={false}
      onClose={() => setPopupInfo()}
      anchor="top"
    >
      <PopupImg
        src={`fotos/${popupInfo.name}.jpg`}
        onClick={() => setModalImageOpen(!modalImageOpen)}
      />
      <Typography variant='caption' sx={{maxWidth: '360px', display: 'block'}}>{popupInfo.description}</Typography>
    </PopupInfo>}
    {
      modalImageOpen && <ModalImage
        url={`fotos/${popupInfo.name}.jpg`}
        open={modalImageOpen}
        onClose={handleImageClose}
      />
    }
    <Geolocate
      positionOptions={{enableHighAccuracy: true}}
      trackUserLocation={true}
      showUserLocation={true}
      showAccuracyCircle={false}
    />
    <Typography onClick={toogleGeolocateControl} sx={geolocateLabelStyle}>Geolocalízame</Typography>
  </Map>;
};

MainContent.propTypes = {
  mapStyle: PropTypes.string.isRequired,
  via: PropTypes.string,
  isToponimiaVisible: PropTypes.bool,
  isTerrainVisible: PropTypes.bool,
  onViaSelected: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  toogleGeolocateControl: PropTypes.func.isRequired,
};

export default MainContent;
