import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PdfIcon = () => <SvgIcon viewBox='0 0 24 24'>
  <g fillRule="evenodd" clipRule="evenodd">
    <path d="M19.556 24.008H5.718c-.043-.016-.084-.036-.127-.047-1.047-.235-1.74-.866-1.998-1.908-.1-.401-.061-.837-.082-1.256-.008-.16 0-.321 0-.482-.85.041-1.313-.445-1.289-1.341.037-1.383.008-2.766.01-4.148 0-.617-.01-1.235.01-1.851.02-.548.436-.941.982-.971.092-.007.182-.002.287-.002v-.311c0-3.024-.002-6.049 0-9.073C3.513 1.429 4.21.456 5.298.114c.139-.046.279-.081.42-.121h10.037c.057.069.105.148.17.213 1.881 1.876 3.762 3.754 5.646 5.625.145.142.209.28.209.485-.006 4.971-.006 9.941-.006 14.912 0 .117 0 .234-.006.352-.041.779-.381 1.403-.986 1.892-.359.292-.791.421-1.226.536zM5.069 20.305c0 .349-.002.66 0 .972.002.779.387 1.171 1.168 1.171h12.801c.799 0 1.176-.383 1.174-1.184-.014-4.776-.027-9.552-.039-14.329v-.276h-.303c-1.053-.001-2.111.018-3.164-.012-.795-.024-1.527-.754-1.557-1.551-.035-1.084-.02-2.172-.027-3.258a4.818 4.818 0 0 0-.016-.281c-.078-.005-.131-.01-.186-.01-2.914.004-5.83.005-8.744.011-.709.001-1.105.406-1.107 1.11-.002 3.033 0 6.066 0 9.1v.233h12.089c.822 0 1.215.397 1.215 1.227 0 1.954-.012 3.908.006 5.862.01.743-.457 1.227-1.23 1.225-3.924-.019-7.848-.01-11.771-.01h-.309zm3.621-6.147v4.274c.586 0 1.16.048 1.725-.01 1.35-.139 2.035-.888 2.076-2.2.033-1.125-.566-1.87-1.674-2.076-.709-.132-1.416-.074-2.127.012zm-2.793 2.765c.363-.037.693-.041 1.01-.107.721-.149 1.139-.688 1.127-1.403-.012-.693-.434-1.165-1.182-1.29-.637-.108-1.273-.044-1.912.039v4.257h.957v-1.496zm7.26-2.808v4.301h.971v-1.737h1.533v-.79h-1.523V14.9h1.639v-.784l-2.62-.001z" />
    <path fill="#FFF" d="M5.069 20.305h.309c3.924 0 7.848-.009 11.771.01.773.002 1.24-.481 1.23-1.225-.018-1.954-.006-3.908-.006-5.862 0-.829-.393-1.227-1.215-1.227H5.069v-.233c0-3.033-.002-6.066 0-9.1.002-.704.398-1.109 1.107-1.11 2.914-.006 5.83-.007 8.744-.011.055 0 .107.005.186.01.008.102.014.189.016.281.008 1.086-.008 2.174.027 3.258.029.797.762 1.526 1.557 1.551 1.053.029 2.111.011 3.164.012h.303v.276c.012 4.777.025 9.553.039 14.329.002.801-.375 1.184-1.174 1.184H6.237c-.781 0-1.166-.392-1.168-1.171-.002-.312 0-.624 0-.972z"/>
    <path fill="#FFF" d="M8.69 14.158c.711-.086 1.418-.145 2.127-.012 1.107.206 1.707.951 1.674 2.076-.041 1.313-.727 2.062-2.076 2.2-.564.058-1.139.01-1.725.01v-4.274zm.973 3.513c.652.134 1.27-.086 1.555-.54.316-.498.309-1.396-.018-1.826-.313-.411-.973-.597-1.537-.424v2.79zM5.897 16.923v1.495H4.94v-4.257c.639-.083 1.275-.147 1.912-.039.748.125 1.17.597 1.182 1.29.012.715-.406 1.254-1.127 1.403-.316.067-.646.071-1.01.108zm.006-.758c.273-.034.525-.034.754-.104.283-.084.434-.366.41-.661-.021-.297-.189-.5-.504-.56a2.205 2.205 0 0 0-.533-.012c-.047.005-.121.094-.123.146-.01.372-.004.746-.004 1.191zM13.157 14.115h2.619v.784h-1.639v.989h1.523v.79h-1.533v1.737h-.971l.001-4.3z"/>
    <path d="M9.663 17.671v-2.79c.564-.173 1.225.013 1.537.424.326.431.334 1.328.018 1.826-.285.454-.903.674-1.555.54zM5.903 16.165c0-.445-.006-.819.004-1.191.002-.052.076-.141.123-.146.178-.013.361-.02.533.012.314.06.482.263.504.56.023.295-.127.577-.41.661-.228.07-.48.07-.754.104z" />
  </g>
</SvgIcon>;

export default PdfIcon;