import ReactDOM from 'react-dom';
import React from 'react';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from './theme';
import {ConfigProvider} from './contexts/Config';
import Home from './views/Home';

const App = () =>
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme()}>
      <CssBaseline/>
      <ConfigProvider>
        <Home/>
      </ConfigProvider>
    </ThemeProvider>
  </StyledEngineProvider>;

const target = document.getElementById('app');
if (target) ReactDOM.render(<App/>, target);

export default App;
