import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const BASE_DPI = 96;
const INCH_TO_METER = 100 / 2.54;
const DEVICE_PIXEL_RATIO = window.devicePixelRatio || 1;
const SCREEN_PIXELS_PER_METER = BASE_DPI * DEVICE_PIXEL_RATIO * INCH_TO_METER;

const NumericScale = ({zoom, lat, sx}) => {
  const computedSx = {
    position: 'absolute',
    ...sx
  };

  const mapMetersPerPixel = 156543.03392 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom);
  const scaleDenominator = Math.round(SCREEN_PIXELS_PER_METER * mapMetersPerPixel).toLocaleString('es-ES');

  return <Typography sx={computedSx} variant='caption'>Escala 1 : {scaleDenominator}</Typography>;
};

NumericScale.propTypes = {
  zoom: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
  sx: PropTypes.object
};

NumericScale.defaultProps = {
  sx: {}
};

export default NumericScale;
