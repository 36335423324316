import React, {useState} from 'react';
import PropTypes from 'prop-types';

import styled from '@mui/styles/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

import ResponsiveHeader from '@geomatico/geocomponents/ResponsiveHeader';
import SidePanel from '@geomatico/geocomponents/SidePanel';

const WIDESCREEN_STEP = '770px';
const DRAWER_WIDTH = '300px';

const MainContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'leftDrawer'
})(({theme, leftDrawer}) => ({
  flexGrow: 1,
  padding: 0,
  position: 'absolute',
  top: theme.mixins.toolbar.minHeight,
  bottom: 0,
  right: 0,
  left: leftDrawer ? DRAWER_WIDTH : 0
}));

const Layout = (props) => {
  const {
    headerLogo,
    headerContent,
    headerTitle,
    sidePanelContent,
    mainContent
  } = props;

  const widescreen = useMediaQuery(`(min-width: ${WIDESCREEN_STEP})`, {noSsr: true});
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const handleClose = () => setIsSidePanelOpen(!isSidePanelOpen);

  return <>
    <ResponsiveHeader
      logo={headerLogo}
      onStartIconClick={widescreen ? undefined : handleClose}
      title={headerTitle}
    >
      {headerContent}
    </ResponsiveHeader>
    <SidePanel
      drawerWidth={DRAWER_WIDTH}
      anchor="left"
      isOpen={isSidePanelOpen}
      onClose={handleClose}
      widescreen={widescreen}
    >
      {sidePanelContent}
    </SidePanel>
    <MainContent leftDrawer={isSidePanelOpen && widescreen}>
      {mainContent}
    </MainContent>
  </>;
};

Layout.propTypes = {
  headerLogo: PropTypes.element,
  headerContent: PropTypes.element,
  headerTitle: PropTypes.string.isRequired,
  sidePanelContent: PropTypes.element.isRequired,
  mainContent: PropTypes.element.isRequired
};

export default Layout;
