import React from 'react';
import PropTypes from 'prop-types';
import ColorSwitch from '@geomatico/geocomponents/ColorSwitch';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const LayerSwitch = ({label, checked, onChange, color}) => {
  return <Box display='flex' flexDirection='row' alignItems='center' ml={2}>
    <ColorSwitch color={color} checked={checked} onChange={onChange}/>
    <Typography ml={1} sx={{fontSize: 13}}>{label}</Typography>
  </Box>;
};

LayerSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string
};

LayerSwitch.defaultProps = {
  color: '#000'
};

export default LayerSwitch;