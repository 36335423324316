import React from 'react';
import PropTypes from 'prop-types';

import useFetch from '../hooks/useFetch';

const ConfigContext = React.createContext({});

const ConfigProvider = ({children}) => {
  const {isWaiting, data, error} = useFetch('config.json');
  return <>
    {isWaiting && <div>Obteniendo configuración...</div>}
    {error && <div>Error leyendo configuración: {error}</div>}
    {data &&
      <ConfigContext.Provider value={data}>
        {children}
      </ConfigContext.Provider>
    }
  </>;
};

ConfigProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export {ConfigContext, ConfigProvider};
