import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MapIcon from '@mui/icons-material/Map';

import BaseMapList from '@geomatico/geocomponents/BaseMapList';

import {ConfigContext} from '../contexts/Config';
import SectionTitle from '../components/SectionTitle';
import KmzIcon from '../components/KmzIcon';
import PdfIcon from '../components/PdfIcon';
import DownloadButton from '../components/DownloadButton';
import LayerSwitch from '../components/LayerSwitch';
import Logo_geomatico from '../img/Logo_geomatico.png';

const SidePanelContent = ({mapStyle, onMapStyleChanged, via, onViaSelected, categories, onCategoriesChanged, isToponimiaVisible, toogleToponimiaVisibility, isTerrainVisible, toogleTerrainVisibility}) => {
  const {baseMapStyles, estados, vias} = useContext(ConfigContext);
  const handleViaSelected = value => onViaSelected(value === '-' ? '' : value);
  const onStatesChanged = (id, checked) => {
    checked ? onCategoriesChanged([...categories, id]) : onCategoriesChanged(categories.filter(item => item !== id));
  };

  const link = {
    textDecoration: 'none',
    color: '#727272',
    fontSize: 13,
    mt: 0.5,
    mb: 0.5,
    '&:hover': {
      color: '#C90C10'
    }
  };

  return <>
    <Box mt={2}>
      <SectionTitle title="Vía seleccionada"/>
      <FormControl fullWidth>
        <Select
          sx={{height: '32px'}}
          value={via || ''}
          onChange={e => handleViaSelected(e.target.value)}
          displayEmpty
          renderValue={value => value ?
            <>{value}. {vias.find(via => via.id === value).label}</>
            : <em>Selecciona una vía...</em>
          }
        >
          {vias.map(via =>
            <MenuItem key={via.id} value={via.id} sx={{fontSize: 13, padding: '3px'}}>
              {via.id}. {via.label}
            </MenuItem>
          )}
        </Select>
        { via &&
          <Button
            size='small'
            sx={{textTransform: 'none', fontStyle: 'italic', fontSize: 13, mt: 1}}
            startIcon={<MapIcon/>}
            onClick={() => handleViaSelected()}
          >
            Vista general
          </Button>
        }
      </FormControl>
    </Box>
    {
      via && <>
        <SectionTitle title="Descargas"/>
        <Box display='flex' flexDirection='row' justifyContent='space-between' mx={2.5}>
          <DownloadButton
            tooltipLabel='Descripción de la vía'
            label='PDF'
            via={via}
          >
            <PdfIcon/>
          </DownloadButton>
          <DownloadButton
            tooltipLabel='Trazado vectorial de la vía'
            label='KMZ'
            via={via}
          >
            <KmzIcon/>
          </DownloadButton>
        </Box>
      </>}
    <Box>
      <SectionTitle title="Cartografía base"/>
      <BaseMapList
        styles={baseMapStyles}
        selectedStyleUrl={mapStyle}
        onStyleChange={onMapStyleChanged}
        variant='list'
        thumbnailWidth={32}
        thumbnailHeight={24}
        sx={{
          '&.BaseMapList-root': {
            paddingLeft: '18px'
          },
          '& .BaseMapList-item': {
            margin: 0
          },
          '& .BaseMapList-text': {
            fontSize: 13,
            padding: '0 7px',
            '&.BaseMapList-selected': {
              color: 'secondary.main'
            }
          },
          '& .BaseMapList-thumbnail': {
            margin: '2.5px 0',
            '&.BaseMapList-selected': {
              borderColor: 'secondary.main'
            }
          }
        }}
      />
    </Box>
    <Box>
      <SectionTitle title="Datos adicionales"/>
      <LayerSwitch label='Ciudades Romanas' checked={isToponimiaVisible} onChange={toogleToponimiaVisibility}/>
      <LayerSwitch label='Terreno 3D (+botón derecho ratón)' checked={isTerrainVisible} onChange={toogleTerrainVisibility}/>

    </Box>
    <Box>
      <SectionTitle title="Leyenda"/>
      {
        estados.map(({id, label, color}) =>
          <LayerSwitch
            key={id}
            label={label}
            color={color}
            checked={categories.includes(id)}
            onChange={(checked) => onStatesChanged(id, checked)}
          />)
      }
    </Box>
    <Box>
      <SectionTitle title="Para saber más..."/>
      <Button startIcon={<FileDownloadIcon/>} sx={{textTransform: 'none', fontSize: 13}} fullWidth onClick={() => window.open('pdf/00_Vias_romanas_en_Castilla_y_Leon.pdf', '_blank')}>
          PDF Vías romanas en Castilla y León
      </Button>
      <Box display='flex' flexDirection='column' ml={1}>
        <Link
          title='Vías Romanas Isaac Moreno Gallo'
          href='http://www.traianvs.net/viasromanas/index.php'
          target='_blank'
          variant='body2'
          sx={link}
        >
        Web sobre vías romanas
        </Link>
        <Link href='mailto:morenogallo@gmail.com?Subject=Vías romanas en Castilla y León' variant='body2' sx={link}>Isaac Moreno Gallo © 2011-2021</Link>
        <Link href='https://geomatico.es' variant='body2' target='_blank' sx={link}>
          <img src={Logo_geomatico} width={80} alt='geomatico.es' style={{float: 'right', margin: '10px 0 5px 0'}}/>
        </Link>
      </Box>
    </Box>
  </>;
};

SidePanelContent.propTypes = {
  mapStyle: PropTypes.string.isRequired,
  onMapStyleChanged: PropTypes.func.isRequired,
  via: PropTypes.string,
  onViaSelected: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCategoriesChanged: PropTypes.func.isRequired,
  isGeolocateControlActive: PropTypes.bool.isRequired,
  isToponimiaVisible: PropTypes.bool.isRequired,
  toogleToponimiaVisibility: PropTypes.func.isRequired,
  isTerrainVisible: PropTypes.bool.isRequired,
  toogleTerrainVisibility: PropTypes.func.isRequired,
};

export default SidePanelContent;

