import React, {useContext, useState} from 'react';
import {ConfigContext} from '../contexts/Config';

import Logo from '../img/Logo.jpg';
import Logo_cyl from '../img/Logo_cyl.png';

import Layout from './Layout';
import MainContent from './MainContent';
import SidePanelContent from './SidePanelContent';
import Typography from '@mui/material/Typography';

const Home = () => {
  const config = useContext(ConfigContext);

  const [mapStyle, setMapStyle] = useState(config.baseMapStyles[config.defaultBasemapIndex].url);
  const [categories, setCategories] = useState(config.estados.flatMap(e => e.id));
  const [via, setVia] = useState();
  const [isToponimiaVisible, setToponimiaVisibility] = useState(false);
  const [isTerrainVisible, setTerrainVisibility] = useState(false);

  const toogleGeolocateControl = () => {
    document.querySelector('.mapboxgl-ctrl-geolocate').click();
  };
  const toogleToponimiaVisibility = () => setToponimiaVisibility(!isToponimiaVisible);
  const toogleTerrainVisibility = () => setTerrainVisibility(!isTerrainVisible);

  const sidePanelContent = <SidePanelContent
    mapStyle={mapStyle}
    onMapStyleChanged={setMapStyle}
    via={via}
    onViaSelected={setVia}
    categories={categories}
    onCategoriesChanged={setCategories}
    toogleToponimiaVisibility={toogleToponimiaVisibility}
    isToponimiaVisible={isToponimiaVisible}
    toogleTerrainVisibility={toogleTerrainVisibility}
    isTerrainVisible={isTerrainVisible}
  />;

  const mainContent = <MainContent
    toogleGeolocateControl={toogleGeolocateControl}
    isToponimiaVisible={isToponimiaVisible}
    isTerrainVisible={isTerrainVisible}
    mapStyle={mapStyle}
    via={via}
    onViaSelected={setVia}
    categories={categories}
  />;

  return <Layout
    headerContent={<img src={Logo} width={160} alt='Vías romanas en Castilla y León'/>}
    headerLogo={<img src={Logo_cyl} width={150} alt='Junta de Castilla y Leon'/>}
    headerTitle={<Typography variant='h5' sx={{mt:0, p:0}}>Vías Romanas en Castilla y León</Typography>}
    sidePanelContent={sidePanelContent}
    mainContent={mainContent}
  />;
};

export default Home;
