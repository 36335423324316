import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import {ConfigContext} from '../contexts/Config';

const downloadIcon = {
  color: 'secondary.main',
  py: 0.5,
  px: 2.5,
  borderRadius: 1,
  border: '2px solid',
  borderColor: 'secondary.main',
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#aaa',
    borderRadius: 1
  },
};

const DownloadButton = ({tooltipLabel, via, label, children}) => {
  const {vias} = useContext(ConfigContext);
  const getViaNameFromViaId = (viaId) =>  vias.find(via => via.id === viaId).label.replace(/\s+/g, '_');
  const getUrlFromFileFormat = (formatFile) => formatFile === 'PDF' ?
    `pdf/${via}_${getViaNameFromViaId(via)}.pdf` :
    `kmz/${via}_${getViaNameFromViaId(via)}.kmz`;
  const handleDownloadFile = () => via && window.open(`${getUrlFromFileFormat(label)}`, '_blank');

  return <Tooltip title={tooltipLabel}>
    <IconButton sx={downloadIcon} onClick={handleDownloadFile}>
      {children}
      <Typography sx={{ml:1}}>{label}</Typography>
    </IconButton>
  </Tooltip>;
};

DownloadButton.propTypes = {
  tooltipLabel: PropTypes.string,
  label: PropTypes.string,
  via: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};

DownloadButton.defaultProps = {};

export default DownloadButton;